#drop-area {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
}

#drop-area.highlight {
    background-color: #f0f0f0;
}

#result-container {
    margin-top: 40px;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.my-form {
    margin-top: 20px;
}

td {
    position: relative;
}

.dotdotdot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: larger;
    font-weight: bold;
}

.more {
    display: none
}

.expanded {
    display: block;
}

.dotdotdot-hide {
    display: none
}

.more-link {
    color: blue;
    cursor: pointer;
}

.hidden {
    display: none;
}